import { useMutation } from '@tanstack/react-query';
import { PostRecognitionProps } from '../../types/recognitions';
import { postRecognition } from '../../../services/recognitions';
import { modalError, modalSuccess } from '../../../utils';

interface RecognitionMutationProps {
  postRecognitionProps?: PostRecognitionProps;
}

export const useRecognitionMutation = () => {
  const postRecognitionMutation = useMutation({
    mutationFn: async ({ postRecognitionProps: { form } }: RecognitionMutationProps): Promise<any> =>
      await postRecognition(form),

    onSuccess(success, variables) {
      const {
        postRecognitionProps: { successMessage, form, setForm, setMembersSelected },
      } = variables;

      modalSuccess(successMessage);
      setForm({
        ...form,
        idTipoReconocimiento: null,
        descripcion: '',
        usuariosReconocidos: [],
      });
      setMembersSelected([]);
    },

    onError: (error, variables) => {
      const {
        postRecognitionProps: { errorMessage },
      } = variables;

      modalError(errorMessage);
    },
  });

  return { postRecognitionMutation };
};
